.loaderContainer {
  width: 100%;
  padding: 4em 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderContainer > svg {
  font-size: 3em;
  color: #666;
}
