.header {
  height: 4em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3em 0 3em;
  top: 0;
  z-index: 99;
  background: #fff;
  width: 100%;
}

.brand {
  height: 3.2em;
  padding: 0.4em;
}

.logo {
  height: 100%;
}

.logoText {
  height: 100%;
  margin-left: 1em;
  padding: 0.3em 0;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.button {
  font-size: 1em;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5em;
  color: #666;
  height: 100%;
  cursor: pointer;
  outline: 0;
}
.icon {
  font-size: 1.4em;
  color: #999;
}

.userImage {
  width: 2em;
  height: 2em;
  border-radius: 2em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  object-fit: cover;
}

.usermenuContainer {
  height: 100%;
  position: relative;
}

.navButtons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navButton {
  height: 3.4em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5em;
  margin: 0 0.5em;
  border-bottom: 3px solid transparent;
  color: #666;
  font-weight: 500;
}

.navButtonActive {
  border-bottom: 3px solid #ee1655;
}

.displayName {
  font-size: 0.85em;
  padding: 0 0.5em 0.1em 0.5em;
  max-width: 9em;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.deviceSelector {
  display: block;
  position: relative;
  height: 100%;
  padding: 0 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deviceValue {
  color: #666;
  cursor: pointer;
}
.deviceValue > span {
  font-size: 1.2em;
  margin: 0 0.5em 0 0;
}

.deviceOptions {
  position: absolute;
}

.deviceOptions {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  top: 100%;
  right: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.deviceOptions > button {
  height: 3em;
  width: 10em;
  padding: 0 2em 0 1em;
  color: #666;
  cursor: pointer;
}

@media (max-width: 790px) {
  .header {
    padding: 0 2em 0 1em;
  }
  .logoText {
    display: none;
  }
}
