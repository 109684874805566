.button {
  color: #666;
  padding: 1em 0;
  width: auto;
  max-width: 6em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #2e9afe;
}

.button > svg {
  font-size: 1.5em;
}
.button > span {
  font-size: 1.2em;
  padding: 0 0 0 0.5em;
}
