.login {
  color: rgba(0, 0, 0, 0.55);
  background: #fff;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0 2em 2em 2em;
  min-height: 48em;
}
.title {
  text-align: center;
  width: 100%;
  color: #777;
  font-weight: normal;
  margin: 0;
  padding: 0 0 0.5em 0;
  font-size: 1.5em;
}
.form {
  padding: 0 1em 0 1em;
  display: block;
  margin: 0 auto;
  width: 360px;
}
.registerLink {
  color: rgba(0, 0, 0, 0.55);
  display: block;
  text-align: center;
  margin: 2em 0 1em 0;
  text-decoration: none;
  font-size: 1em;
}
.login > form > a > strong {
  color: rgb(232, 60, 60);
}
.sendBtn {
  width: 10em;
  margin-top: 2em;
  border: 0;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  letter-spacing: 0.1em;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.05);
  padding: 0 1em;
  border-radius: 4px;
  color: #555;
  height: 2.4em;
}
.errorContainer {
  padding: 0 1em;
  color: rgb(232, 60, 60);
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.input {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.recoverLinkContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.recoverLink {
  display: inline;
  text-align: right;
  text-decoration: none;
  color: #666;
  font-size: 0.8em;
  padding: 0.2em 0;
}

.openidButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.openidButton > img {
  display: block;
  width: 1.4em;
  height: 1.4em;
  object-fit: contain;
  object-position: center;
}

.openidButton > div {
  width: 12em;
  text-align: center;
  padding: 0.8em 0.5em;
  font-weight: 500;
}

.buttonContainer {
  padding-top: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.separator {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2em 1em 2em;
}

.separator > div:nth-child(1) {
  width: 100%;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}
.separator > div:nth-child(2) {
  padding: 0 1em;
  color: rgba(0, 0, 0, 0.4);
}
.separator > div:nth-child(3) {
  width: 100%;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.success {
  padding-top: 1em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.intructions {
  padding: 0 0 2em 0;
  text-align: center;
}

.success > h2 {
  font-size: 1.6em;
  padding-bottom: 0.5em;
}

.termsContainer {
  padding-top: 2em;
}

.termsText > a {
  color: #6ddbcb;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 1em 0 2em;
}

.logo {
  display: block;
  width: 100%;
  object-fit: contain;
  max-width: 18em;
  max-height: 18em;
}
