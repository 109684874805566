.container {
  width: 100%;
  height: 100%;
  padding: 2em;
}

.wrapper {
  max-width: 900px;
  margin: 0 auto;
}
