.container {
  min-height: 15em;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.1),
    0 1px 3px 1px rgba(60, 64, 67, 0.1);
  margin-top: 1em;
  background: #fff;
}

.title {
  padding: 1em;
}

.title > span {
  font-size: 1.4em;
  color: #555;
}

.wrapper {
  padding: 2em 3em 2em 2em;
}
