.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.list {
  width: 350px;
  height: calc(100vh - 8em);

  overflow: scroll;
  flex-shrink: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
}

.log {
  border-radius: 6px;
  padding: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: block;
  width: 100%;
  text-align: left;
}

.mapContainer {
  width: 100%;
  height: 100%;
}

.header {
  padding: 0em 1em;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@media (max-width: 700px) {
  .list {
    display: none;
  }
}
