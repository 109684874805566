.deviceSelector {
  display: block;
  position: relative;
  height: 100%;
  padding: 0 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deviceValue {
  color: #666;
  cursor: pointer;
}
.deviceValue > span {
  font-size: 1.2em;
  margin: 0 0.5em 0 0;
}

.deviceOptions {
  position: absolute;
}

.deviceOptions {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  top: 100%;
  right: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.deviceOptions > button {
  height: 3em;
  width: 10em;
  padding: 0 1em 0 1em;
  color: #666;
  cursor: pointer;
  text-align: center;
}

.deviceOptions > a {
  height: 3em;
  width: 10em;
  padding: 0 1em 0 1em;
  color: #666;
  cursor: pointer;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}
