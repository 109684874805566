.container {
  position: absolute;
  z-index: 999;
  background: #fff;
  width: 22em;
  bottom: 3em;
  right: 3em;
  padding-left: 1em;
  width: auto;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
}

.button {
  font-size: 1em;
  padding: 1em;
  font-weight: 500;
  color: #ed1e79;
  cursor: pointer;
}

.button:first-child {
  color: #5882fa;
}

/*#5882FA*/
/*#ed1e79*/
