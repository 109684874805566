.boundsTable {
  min-height: 15em;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.1),
    0 1px 3px 1px rgba(60, 64, 67, 0.1);
  margin-top: 1em;
  background: #fff;
}

.title {
  padding: 1em;
}

.title > span {
  font-size: 1.4em;
  color: #555;
}

.listHeader {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  background: #fafafa;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: calc(11px + 3 * ((100vw - 400px) / 1200));
}

.listHeader > div {
  font-weight: 600;
  color: #555;
  font-size: 0.85em;
  text-align: center;
  padding: 0.7em 0.5em;
}

.listRow {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.listRow > div {
  color: #666;
  text-align: center;
  padding: 1em 0.5em;
  font-size: calc(11px + 3 * ((100vw - 400px) / 1200));
}

.listRow:nth-child(odd) {
  background: rgba(0, 0, 0, 0.035);
}

.body {
  min-height: 10em;
  max-height: 40em;
  overflow-y: scroll;
}
