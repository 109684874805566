.container {
  width: 100%;
  height: 100%;
  padding: 1em 2em 2em 2em;
  height: 100vh;
  overflow: auto;
  background: #fafafa;
}

.graphContainer {
  max-width: 900px;
  margin: 0 auto;
  padding-bottom: 2em;
}

.average {
  display: block;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.averageValues {
  display: flex;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.1),
    0 1px 3px 1px rgba(60, 64, 67, 0.1);
  font-size: calc(12px + 4 * ((100vw - 400px) / 1200));
  background: #fff;
}

.averageValueContainer {
  padding: 2em 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.averageValueContainer:last-child {
  border-right: 0;
}

.averageLabel {
  font-size: 0.9em;
}

.averageValue {
  font-size: 3em;
  font-weight: 500;
  color: #666;
}

.button {
  color: #666;
  padding: 1em 0;
  width: auto;
  max-width: 6em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #2e9afe;
}

.button > svg {
  font-size: 1.5em;
}
.button > span {
  font-size: 1.2em;
  padding: 0 0 0 0.5em;
}

.selector {
  width: 10em;
}

.selectorContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.time {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding-bottom: 1em;
}

.time > div {
  padding: 0 0 0 0.5em;
  font-size: 1em;
  color: #666;
}

.wrapper {
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.1),
    0 1px 3px 1px rgba(60, 64, 67, 0.1);
  padding: 1em;
  margin-top: 1em;
}

@media (max-width: 780px) {
  .container {
    padding: 1em;
  }
}
