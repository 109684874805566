.usermenu {
  position: absolute;
  top: calc(100% + 1px);
  right: 0;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.07);
  padding: 0.65em 1em;
}

.usermenuButton {
  display: block;
  padding: 0.65em 0;
  width: 9em;
  word-wrap: none;
  white-space: nowrap;
  text-align: left;
  font-size: 1em;
  color: #666;
  min-height: 16px;
  cursor: pointer;
}

.usermenuButton:hover {
  font-weight: 500;
}

.separator {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0.65em 0;
}
