.container {
  width: 100%;
  height: 100%;
  padding: 2em;
}

.list {
  width: 100%;
  padding: 2em;
}

.device {
  margin-bottom: 0.5em;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 1.5em;
}

.empty {
  width: 100%;
  padding: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8em;
  color: #999;
}

.mapContainer {
  display: block;
  max-width: 900px;
  margin: 0 auto;
}

.deviceSelectorContainer {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 0 1em 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.deviceSelectorContainer > div {
  width: 10em;
}

.optionsContainer {
  max-width: 900px;
  margin: 0 auto;
  padding: 1.5em 0 1.5em 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 1.5em;
}

.option {
  width: 100%;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.08);
  padding: 1em;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
}

.option:hover {
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.12);
}

.option > svg {
  font-size: 2.2em;
  color: rgba(0, 0, 0, 0.5);
}

.option > div {
  margin-top: 1em;
  color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 780px) {
  .optionsContainer {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 580px) {
  .optionsContainer {
    grid-template-columns: 1fr 1fr;
  }
}
